export default {
  items: [{
      label: "開発・プログラミング",
      choices: [{

          value: "開発ディレクション",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "サーバー・ネットワーク構築",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "システム運用・保守",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "Webサービス開発",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "アプリケーション開発",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "スマホアプリ開発",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "ゲーム開発",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "AI開発",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "ロボット開発",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
      ]
    },
    {
      label: "Web制作",
      choices: [{

          value: "Webサイト制作ディレクション",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "Webサイト制作",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "Webデザイン",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "UXデザイン",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "ECサイト構築・運用",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
      ]
    },
    {
      label: "デザイン",
      choices: [{

          value: "プロダクトデザイン",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "ロゴデザイン",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "イラスト・キャラクターデザイン",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "バナー・POPデザイン",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "書籍・冊子デザイン",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "インフォグラフィック",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
      ]
    },
    {
      label: "編集・ライティング",
      choices: [{

          value: "Webライティング",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "雑誌書籍ライティング",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "ネーミング・コピーライティング",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "ジャーナリスト",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "コラムニスト",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "Web編集",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "雑誌書籍編集",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "校正",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
      ]
    },
    {
      label: "映像・音楽制作",
      choices: [{

          value: "テレビ番組制作",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "動画制作",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "アニメーション制作",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "CG制作",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "音楽制作",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "映像撮影",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "スチール撮影",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "構成作家・脚本家",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "MC・ナレーション",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "ポッドキャスティング",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
      ]
    },
    {
      label: "経営・マネジメント",
      choices: [{

          value: "経営企画",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "新規事業開発",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "財務",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "経理",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "法務",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "組織開発",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "労務管理",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "人材育成",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "採用支援",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
      ]
    },
    {
      label: "マーケティング",
      choices: [{

          value: "広告・宣伝企画",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "Web広告運用",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "広報・PR",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "SNS運用",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "Web解析・分析",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "SEO",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "定量調査・分析",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "定性調査・分析",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "統計データ解析",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "グロースハック",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "コミュニティマネジメント",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "イベントプランニング",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "ミステリーショッパー",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
      ]
    },
    {
      label: "セールス・事務",
      choices: [{

          value: "法人営業",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "個人営業",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "資料作成",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "カスタマーサポート",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "秘書",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "データ入力",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "軽作業代行",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
      ]
    },
    {
      label: "翻訳・通訳サービス",
      choices: [{

          value: "英語翻訳",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "中国語翻訳",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "韓国語翻訳",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "フランス語翻訳",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "スペイン語翻訳",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "ドイツ語翻訳",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "その他翻訳",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "英語通訳",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "中国語通訳",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "韓国語通訳",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "フランス語通訳",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "スペイン語通訳",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "ドイツ語通訳",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "その他通訳",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
      ]
    },
    {
      label: "士業",
      choices: [{

          value: "弁護士",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "弁理士",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "公認会計士",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "税理士",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "司法書士",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "行政書士",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "社労士",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "一級建築士",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "二級建築士",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "不動産鑑定士",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "中小企業診断士",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
      ]
    },
    {
      label: "コーチ・カウンセリング",
      choices: [{

          value: "コーチング",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "キャリアカウンセラー",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "キャリアコンサルタント",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
      ]
    },
    {
      label: "ホーム・ケアワーク",
      choices: [{

          value: "ベビーシッター",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "家事代行",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "整理収納",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "ハウスクリーニング",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "ケアマネージャー",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
      ]
    },
    {
      label: "ファッション・美容",
      choices: [{

          value: "スタイリスト",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "ファッションデザイナー",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "美容師",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "メイクアップアーティスト",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "ネイリスト",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {

          value: "エステティシャン",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        }
      ]
    }
  ]
}
