export default {
  items: [{
      label: "チャットツール",
      choices: [{
          value: "LINE",
          label: "LINE",
          customProperties: {
            en: "LINE",
            jp1: "ライン",
            jp2: "",
          }
        },
        {
          value: "Chatwork",
          customProperties: {
            en: "chatwork",
            jp1: "チャットワーク",
            jp2: "",
          }
        },
        {
          value: "Slack",
          customProperties: {
            en: "slack",
            jp1: "スラック",
            jp2: "",
          }
        },
        {
          value: "Facebookメッセンジャー",
          customProperties: {
            en: "Facecbook messenger",
            jp1: "フェイスブックメッセンジャー",
            jp2: "メッセンジャー",
          }
        },
        {
          value: "WhatsApp",
          customProperties: {
            en: "whatsapp",
            jp1: "ワッツアップ",
            jp2: "",
          }
        },
      ]
    },
    {
      label: "プロジェクト管理",
      choices: [{
          value: "backlog",
          customProperties: {
            en: "backlog",
            jp1: "バックログ",
            jp2: "",
          }
        },
        {
          value: "Trello",
          customProperties: {
            en: "Trello",
            jp1: "トレロ",
            jp2: "",
          }
        },
        {
          value: "Jooto",
          customProperties: {
            en: "",
            jp1: "ジョートー",
            jp2: "",
          }
        },
      ]
    },
    {
      label: "ビデオ会議ツール",
      choices: [{
          value: "skype",
          customProperties: {
            en: "skype",
            jp1: "スカイプ",
            jp2: "",
          }
        },
        {
          value: "ZOOM",
          customProperties: {
            en: "ZOOM",
            jp1: "ズーム",
            jp2: "",
          }
        },
        {
          value: "appear.in",
          customProperties: {
            en: "appear in",
            jp1: "アピアイン",
            jp2: "アピア―イン",
          }
        },
        {
          value: "Googleハングアウト",
          customProperties: {
            en: "Google Hangout",
            jp1: "ハングアウト",
            jp2: "",
          }
        },
        {
          value: "Web Components",
          customProperties: {
            en: "Web Components",
            jp1: "ウェブコンポーネンツ",
            jp2: "",
          }
        },
      ]
    },
    {
      label: "SNS",
      choices: [{
          value: "Twitter",
          customProperties: {
            en: "Twitter",
            jp1: "ツイッター",
            jp2: "",
          }
        },
        {
          value: "Facebook",
          customProperties: {
            en: "Facebook",
            jp1: "フェイスブック",
            jp2: "",
          }
        },
        {
          value: "Facebookページマネージャー",
          customProperties: {
            en: "Facebook Page Manager",
            jp1: "フェイスブックページマネージャー",
            jp2: "ページマネージャー",
          }
        },
        {
          value: "Instagram",
          customProperties: {
            en: "Instagram",
            jp1: "インスタ",
            jp2: "インスタグラム",
          }
        },
        {
          value: "mixi",
          customProperties: {
            en: "mixi",
            jp1: "ミクシー",
            jp2: "",
          }
        },
        {
          value: "Snapchat",
          customProperties: {
            en: "Snapchat",
            jp1: "スナップチャット",
            jp2: "",
          }
        },
        {
          value: "TikTok",
          customProperties: {
            en: "TikTok",
            jp1: "ティックトック",
            jp2: "",
          }
        },
        {
          value: "Pinterest",
          customProperties: {
            en: "Pinterest",
            jp1: "ピンタレスト",
            jp2: "",
          }
        },
        {
          value: "Youtube",
          customProperties: {
            en: "Youtube",
            jp1: "ユーチューブ",
            jp2: "",
          }
        },
        {
          value: "LinkedIn",
          customProperties: {
            en: "LinkedIn",
            jp1: "リンクトイン",
            jp2: "",
          }
        },
      ]
    },
    {
      label: "HP・ブログエディター",
      choices: [{
          value: "note",
          customProperties: {
            en: "note",
            jp1: "ノート",
            jp2: "",
          }
        },
        {
          value: "Amebaブログ",
          customProperties: {
            en: "Ameba",
            jp1: "アメブロ",
            jp2: "アメーバブログ",
          }
        },
        {
          value: "Wordpress",
          customProperties: {
            en: "",
            jp1: "ワードプレス",
            jp2: "",
          }
        },
        {
          value: "Wix",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {
          value: "Ameba Ownd",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {
          value: "Jindo",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {
          value: "ペライチ",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {
          value: "Evernote",
          customProperties: {
            en: "Evernote",
            jp1: "エバーノート",
            jp2: "",
          }
        },
        {
          value: "はてなブログ",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {
          value: "FC2",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {
          value: "忍者ホームページ",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
      ]
    },
    {
      label: "開発言語",
      choices: [{
          value: "Perl",
          customProperties: {
            en: "",
            jp1: "パール",
            jp2: "",
          }
        },
        {
          value: "PHP",
          customProperties: {
            en: "",
            jp1: "ピーエイチピー",
            jp2: "",
          }
        },
        {
          value: "Python",
          customProperties: {
            en: "",
            jp1: "パイソン",
            jp2: "",
          }
        },
        {
          value: "Ruby",
          customProperties: {
            en: "",
            jp1: "ルビー",
            jp2: "",
          }
        },
        {
          value: "ASP",
          customProperties: {
            en: "",
            jp1: "エーエスピー",
            jp2: "",
          }
        },
        {
          value: "JSP",
          customProperties: {
            en: "",
            jp1: "ジェイエスピー",
            jp2: "",
          }
        },
        {
          value: "C",
          customProperties: {
            en: "",
            jp1: "シー",
            jp2: "",
          }
        },
        {
          value: "C++",
          customProperties: {
            en: "",
            jp1: "シー",
            jp2: "",
          }
        },
        {
          value: "C#",
          customProperties: {
            en: "",
            jp1: "シー",
            jp2: "",
          }
        },
        {
          value: "Go",
          customProperties: {
            en: "",
            jp1: "ゴー",
            jp2: "",
          }
        },
        {
          value: "Scala",
          customProperties: {
            en: "",
            jp1: "スカーラ",
            jp2: "",
          }
        },
        {
          value: "Swift",
          customProperties: {
            en: "",
            jp1: "スウィフト",
            jp2: "",
          }
        },
        {
          value: "Unity",
          customProperties: {
            en: "",
            jp1: "ユニティ",
            jp2: "",
          }
        },
        {
          value: "Objective-C",
          customProperties: {
            en: "",
            jp1: "オブジェクティブシー",
            jp2: "",
          }
        },
        {
          value: "Java",
          customProperties: {
            en: "",
            jp1: "ジャバ",
            jp2: "",
          }
        },
        {
          value: "VisualBasic",
          customProperties: {
            en: "",
            jp1: "ヴィジュアルベーシック",
            jp2: "",
          }
        },
        {
          value: "Delphi",
          customProperties: {
            en: "",
            jp1: "デルファイ",
            jp2: "",
          }
        },
        {
          value: "COBOL",
          customProperties: {
            en: "",
            jp1: "コボル",
            jp2: "",
          }
        },
        {
          value: "JavaScript",
          customProperties: {
            en: "",
            jp1: "ジャバスクリプト",
            jp2: "",
          }
        },
        {
          value: "ActionScript",
          customProperties: {
            en: "",
            jp1: "アクションスクリプト",
            jp2: "",
          }
        },
        {
          value: "R",
          customProperties: {
            en: "",
            jp1: "アール",
            jp2: "",
          }
        },
        {
          value: "Erlang",
          customProperties: {
            en: "",
            jp1: "アーラン",
            jp2: "",
          }
        },
      ]
    },
    {
      label: "ドキュメンテーション",
      choices: [{
          value: "Word",
          customProperties: {
            en: "",
            jp1: "ワード",
            jp2: "",
          }
        },
        {
          value: "Excel",
          customProperties: {
            en: "",
            jp1: "エクセル",
            jp2: "",
          }
        },
        {
          value: "PowerPoint",
          customProperties: {
            en: "",
            jp1: "パワーポイント",
            jp2: "",
          }
        },
        {
          value: "Access",
          customProperties: {
            en: "",
            jp1: "アクセス",
            jp2: "",
          }
        },
        {
          value: "Google Document",
          customProperties: {
            en: "",
            jp1: "グーグルドキュメント",
            jp2: "",
          }
        },
        {
          value: "Google Spreadsheet",
          customProperties: {
            en: "",
            jp1: "グーグルスプレッドシート",
            jp2: "スプレッドシート",
          }
        },
        {
          value: "Google Slide",
          customProperties: {
            en: "",
            jp1: "グーグルスライド",
            jp2: "スライド",
          }
        },
        {
          value: "Pages",
          customProperties: {
            en: "",
            jp1: "ページズ",
            jp2: "",
          }
        },
        {
          value: "Numbers",
          customProperties: {
            en: "",
            jp1: "ナンバーズ",
            jp2: "",
          }
        },
        {
          value: "Keynote",
          customProperties: {
            en: "",
            jp1: "キーノート",
            jp2: "",
          }
        },
      ]
    },
    {
      label: "マーケティングツール",
      id: 1,
      disabled: false,
      choices: [{
          value: "Google Analytics",
          customProperties: {
            en: "",
            jp1: "グーグルアナリティクス",
            jp2: "アナリティクス",
          }
        },
        {
          value: "Google Search Console",
          customProperties: {
            en: "",
            jp1: "グーグルサーチコンソール",
            jp2: "サーチコンソール",
          }
        },
        {
          value: "Google AdWords",
          customProperties: {
            en: "",
            jp1: "グーグルアドワーズ",
            jp2: "アドワーズ",
          }
        },
        {
          value: "KARTE",
          customProperties: {
            en: "",
            jp1: "カルテ",
            jp2: "",
          }
        },
        {
          value: "Salesforce",
          customProperties: {
            en: "",
            jp1: "セールスフォース",
            jp2: "",
          }
        },
        {
          value: "マルケト",
          customProperties: {
            en: "Marketo",
            jp1: "マルケト",
            jp2: "",
          }
        },
      ]
    },
    {
      label: "デザイン",
      choices: [{
          value: "Photoshop",
          customProperties: {
            en: "",
            jp1: "フォトショップ",
            jp2: "",
          }
        },
        {
          value: "Illustrator",
          customProperties: {
            en: "",
            jp1: "イラストレーター",
            jp2: "",
          }
        },
        {
          value: "InDesign",
          customProperties: {
            en: "",
            jp1: "インデザイン",
            jp2: "",
          }
        },
        {
          value: "DreamWeaver",
          customProperties: {
            en: "",
            jp1: "ドリームウェバー",
            jp2: "",
          }
        },
        {
          value: "FireWorks",
          customProperties: {
            en: "",
            jp1: "ファイヤーワークス",
            jp2: "",
          }
        },
        {
          value: "Flash",
          customProperties: {
            en: "",
            jp1: "フラッシュ",
            jp2: "",
          }
        },
        {
          value: "Figma",
          customProperties: {
            en: "",
            jp1: "フィグマ",
            jp2: "",
          }
        },
        {
          value: "Director",
          customProperties: {
            en: "",
            jp1: "ディレクター",
            jp2: "",
          }
        },
        {
          value: "InDesign",
          customProperties: {
            en: "",
            jp1: "インデザイン",
            jp2: "",
          }
        },
        {
          value: "HTML",
          customProperties: {
            en: "",
            jp1: "エイチティーエムエル",
            jp2: "",
          }
        },
        {
          value: "CSS",
          customProperties: {
            en: "",
            jp1: "シーエスエス",
            jp2: "",
          }
        },
        {
          value: "HTML5",
          customProperties: {
            en: "",
            jp1: "エイチティーエムエル",
            jp2: "",
          }
        },
        {
          value: "CSS3",
          customProperties: {
            en: "",
            jp1: "シーエスエス",
            jp2: "",
          }
        },
        {
          value: "CAD",
          customProperties: {
            en: "",
            jp1: "キャド",
            jp2: "",
          }
        },
        {
          value: "3DCG",
          customProperties: {
            en: "",
            jp1: "スリーディーシージー",
            jp2: "",
          }
        },
        {
          value: "Adobe XD",
          customProperties: {
            en: "",
            jp1: "アドビ",
            jp2: "",
          }
        },
        {
          value: "Prott",
          customProperties: {
            en: "",
            jp1: "プロト",
            jp2: "",
          }
        },
        {
          value: "Sketch",
          customProperties: {
            en: "",
            jp1: "スケッチ",
            jp2: "",
          }
        },
      ]
    },
    {
      label: "動画編集",
      choices: [{
          value: "Premiere Pro",
          customProperties: {
            en: "",
            jp1: "プレミア",
            jp2: "",
          }
        },
        {
          value: "Premiere Elements",
          customProperties: {
            en: "",
            jp1: "プレミアエレメンツ",
            jp2: "",
          }
        },
        {
          value: "Premiere Rush",
          customProperties: {
            en: "",
            jp1: "プレミアラッシュ",
            jp2: "",
          }
        },
        {
          value: "After Effects",
          customProperties: {
            en: "",
            jp1: "アフターエフェクト",
            jp2: "",
          }
        },
        {
          value: "Power Director",
          customProperties: {
            en: "",
            jp1: "パワーディレクター",
            jp2: "",
          }
        },
        {
          value: "Lightworks",
          customProperties: {
            en: "",
            jp1: "ライトワークス",
            jp2: "",
          }
        },
        {
          value: "Final Cut Pro",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
        {
          value: "DaVinci",
          customProperties: {
            en: "",
            jp1: "",
            jp2: "",
          }
        },
      ]
    },
    {
      label: "OS",
      choices: [{

          value: "Windows",
          customProperties: {
            en: "",
            jp1: "ウィンドウズ",
            jp2: "",
          }
        },
        {
          value: "Mac",
          customProperties: {
            en: "",
            jp1: "マック",
            jp2: "",
          }
        },
        {
          value: "Linux",
          customProperties: {
            en: "",
            jp1: "リナックス",
            jp2: "",
          }
        },
        {
          value: "Solaris",
          customProperties: {
            en: "",
            jp1: "ソラリス",
            jp2: "",
          }
        },
        {
          value: "AWS",
          customProperties: {
            en: "",
            jp1: "エーダブルエス",
            jp2: "アマゾンウェブサービス",
          }
        },
      ]
    },
    {
      label: "会計サービス",
      choices: [{
          value: "freee",
          customProperties: {
            en: "freee",
            jp1: "フリー",
            jp2: "",
          }
        },
        {
          value: "マネーフォワード",
          customProperties: {
            en: "MoneyForward",
            jp1: "マネーフォワード",
            jp2: "マネフォ",
          }
        },
        {
          value: "弥生",
          customProperties: {
            en: "Yayoi",
            jp1: "ヤヨイ",
            jp2: "",
          }
        },
      ]
    },
    {
      label: "グループウェア",
      choices: [{
          value: "kintone",
          customProperties: {
            en: "",
            jp1: "キントーン",
            jp2: "",

          }
        },
        {
          value: "サイボウズOffice",
          customProperties: {
            en: "",
            jp1: "サイボウズオフィス",
            jp2: "",
          }
        },
        {
          value: "Desknet",
          customProperties: {
            en: "",
            jp1: "デスクネット",
            jp2: "",
          }
        }
      ]
    },
  ]
}
