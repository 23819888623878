/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// FIXME Migrate to Webpack from Sprockets
// import $ from 'jquery';
// window.$ = window.jQuery = $;
import _ from 'lodash'
import {
  tns
} from 'tiny-slider/src/tiny-slider';
import MicroModal from 'micromodal';
import Choices from 'choices.js';
import ProfileSkills from './skills';
import ProfileTools from './tools';
import YubinBango from 'yubinbango.js';
import StickySidebar from 'sticky-sidebar';
import ClipboardJS from 'clipboard';

let mainModule = (function() {

  const init = () => {
    initSlider();
    initNavDropdown();
    initFaq();
    initMemberModal();
    initFixedNav();
    initScrollLinks();
    initNavMenu();
    initBenefitModal();
    initFormChoices();
    initAddressCompletion();
    initAddressCompletionVerB();
    initProfileSkillInput();
    initFormButtonLinks();
    initMobileProfileNav();
    initPopover();
    initCopyButton();
    initFreegalModal();
    initRegisterProfileModal();
  }

  const initCopyButton = () => {
    if ($(".js-copy-btn").length) {
      let clipboard = new ClipboardJS('.js-copy-btn');
      if ($(".js-cards-benefit, .js-cards-coworking, .aboutMeBtnIn").length) {
        clipboard.on('success', (evt) => {
          $(evt.trigger).text('コピーしました');
        });
      }
    }
  }

  const initPopover = () => {
    $(".js-popover").click(function() {
      $(this).toggleClass('-is-active');
      document.addEventListener('click', actionListener);
    });
    const actionListener = (event) => {
      if (!$(event.target).closest('.js-popover').length) {
        $('.js-popover').removeClass('-is-active');
        document.removeEventListener('click', actionListener);
      }
    };
  }

  const initMobileProfileNav = () => {
    $(".js-profile-menunav").click(function() {
      $("#sidebar").toggle();
    });
  }

  const initFormChoices = () => {
    let jobtype = $('.js-jobtype')[0];
    if (jobtype && jobtype.length) {
      const choices = new Choices(jobtype, {
        itemSelectText: '職種を選択する',
        removeItems: true,
        resetScrollPosition: false,
        removeItemButton: true,
        shouldSort: false
      });
    }
  }

  const initFormButtonLinks = () => {
    $('.js-btn-submit').on('click', function(e) {
      e.preventDefault();
      $(this).attr("disabled", true);
      if ($('#tmp-save').length) {
        $('#tmp-save').attr("disabled", true);
      }
      $(this).closest('form').submit();
    });
  }

  const initAddressCompletion = () => {
    $('.js-a-search').click(function() {
      $('.js-a-post-error').text('');
      $('.js-a-post-error').hide();

      if ($('.js-a-postal').val().length) {
        let postal = $('.js-a-postal').val();

        YubinBango.getAddress(postal).then(res => {
          $(".js-a-prefecture").val(res.prefecture);
          $(".js-a-locality").val(res.locality + res.street);

        }).catch(e => {
          $('.js-a-post-error').text('郵便番号が正しくありません');
          $('.js-a-post-error').show();
        });
      }
    });
  }

  const initAddressCompletionVerB = () => {
    $('.js-b-search').click(function() {
      $('.js-b-post-error').text('');
      $('.js-b-post-error').hide();

      if ($('.js-b-postal').val().length) {
        const postal = $('.js-b-postal').val();

        YubinBango.getAddress(postal).then(res => {
          // 分割された入力テキストではなく1つの入力テキストに設定するのが
          // initAddressCompletionとの違いです.
          $(".js-b-address").val(res.prefecture + res.locality + res.street);
        }).catch(e => {
          $('.js-b-post-error').text('郵便番号が正しくありません');
          $('.js-b-post-error').show();
        });
      }
    });
  }

  const initProfileSkillInput = () => {
    let skillsInput = $(".js-profile-skills")[0];
    let toolsInput = $(".js-profile-tools")[0];
    let skillsSubInput = $(".js-profile-skills-sub")[0];
    let toolsSubInput = $(".js-profile-tools-sub")[0];
    if (skillsInput) {
      let skills = ProfileSkills.items;
      let tools = ProfileTools.items;

      const skillChoices = new Choices(skillsInput, {
        // choices:skills,
        searchFields: ['label', 'value', 'customProperties.en', 'customProperties.jp1', 'customProperties.jp2'],
        itemSelectText: 'スキルを選択する',
        removeItems: true,
        removeItemButton: true,
        resetScrollPosition: false,
        shouldSort: false
      });

      const skillSubChoices = new Choices(skillsSubInput, {
        // choices:skills,
        searchFields: ['label', 'value', 'customProperties.en', 'customProperties.jp1', 'customProperties.jp2'],
        itemSelectText: 'サブスキルを選択する',
        removeItems: true,
        removeItemButton: true,
        resetScrollPosition: false,
        shouldSort: false
      });

      const toolChoices = new Choices(toolsInput, {
        // choices:tools,
        searchFields: ['label', 'value', 'customProperties.en', 'customProperties.jp1', 'customProperties.jp2'],
        itemSelectText: 'ツールを選択する',
        removeItems: true,
        removeItemButton: true,
        resetScrollPosition: false,
        shouldSort: false
      });

      const toolSubChoices = new Choices(toolsSubInput, {
        // choices:tools,
        searchFields: ['label', 'value', 'customProperties.en', 'customProperties.jp1', 'customProperties.jp2'],
        itemSelectText: 'サブツールを選択する',
        removeItems: true,
        removeItemButton: true,
        resetScrollPosition: false,
        shouldSort: false
      });
    }
  }

  const initBenefitModal = () => {
    MicroModal.init();
  }

  const initFixedNav = () => {
    let nav = $(".js-menu-bar");
    let trigger;
    if (nav.length) {

      trigger = $('.js-menu-bar').offset().top;
      $(window).on('scroll', _.throttle(function() {
        toggleState()
      }, 100));

      const toggleState = () => {
        if (window.pageYOffset >= trigger) {
          nav.addClass("-active");
        } else {
          nav.removeClass("-active");
        }
      }

      // conflicting with smooth scroolling somewhere...
      $('.js-menu-bar a').click(function() {
        event.preventDefault();
        let anchor = $(this).attr('href');
        let offset = $(this).data('offset') || -160;
        window.history.pushState(null, null, $(this).attr('href'));
        $('html, body').animate({
          scrollTop: $(anchor).offset().top + offset
        }, 500);
      })

      if ($('#js-menu-bar-coworkings').length) {
        const menus = {
          '#menu0': 0,
          '#menu1': 0,
          '#menu2': 0,
          '#menu3': 0,
          '#menu4': 0,
          '#menu5': 0,
          '#menu6': 0,
          '#menu7': 0,
          '#menu8': 0,
          '#menu9': 0,
        };

        let globalNavi = {};

        // 各要素のスクロール値を保存
        for (const key in menus) {
          if ($(key).offset()) {
            menus[key] = $(key).offset().top - 10;
            globalNavi[key] = $('#js-menu-bar-coworkings ul li a[href="' + key + '"]');
          }
        }

        $(window).scroll(function () {
          for (var key in menus) {
            if ($(window).scrollTop() > menus[key] - 100) {
              $('#js-menu-bar-coworkings ul li a').each(function() {
                $(this).removeClass('active');
              });
              globalNavi[key].addClass('active');
            }
          }
        });
      }

      if ($('#js-menu-bar-benefits').length) {
        const menus = {
          '#insurance': 0,
          '#welbox': 0,
          '#accounting': 0,
          '#life': 0,
          '#money': 0,
          '#career': 0,
          '#legal': 0,
        };

        let globalNavi = {};

        // 各要素のスクロール値を保存
        for (const key in menus) {
          if ($(key).offset()) {
            menus[key] = $(key).offset().top - 10;
            globalNavi[key] = $('#js-menu-bar-benefits ul li a[href="' + key + '"]');
          }
        }

        $(window).scroll(function () {
          for (var key in menus) {
            if ($(window).scrollTop() > menus[key]) {
              $('#js-menu-bar-benefits ul li a').each(function() {
                $(this).removeClass('active');
              });
              globalNavi[key].addClass('active');
            }
          }
        });
      }

      if ($('#js-menu-bar-mypage').length) {
        const menus = {
          '#benefits': 0,
          '#events': 0,
          '#connections': 0,
          '#handbook': 0,
          '#community': 0,
          '#resources': 0,
        };

        let globalNavi = {};

        // 各要素のスクロール値を保存
        for (const key in menus) {
          if ($(key).length) {
            if ($(key).offset()) {
              menus[key] = $(key).offset().top - 10;
              globalNavi[key] = $('#js-menu-bar-mypage ul li a[href="' + key + '"]');
            }
          } else {
            delete menus[key];
          }
        }

        $(window).scroll(function () {
          for (var key in menus) {
            if ($(window).scrollTop() > menus[key] - 10) {
              $('#js-menu-bar-mypage ul li a').each(function () {
                $(this).removeClass('active');
              });
              if (typeof globalNavi[key] != 'undefined') {
                globalNavi[key].addClass('active');
              }
            }
          }
        });
      }

      if ($('#js-menu-bar-about').length) {
        const menus = {
          '#activity': 0,
          '#principle': 0,
          '#team': 0,
          '#advisory': 0,
          '#partners': 0,
          '#message': 0,
          '#aboutus': 0,
        };

        let globalNavi = {};

        // 各要素のスクロール値を保存
        for (const key in menus) {
          if ($(key).offset()) {
            menus[key] = $(key).offset().top - 10;
            globalNavi[key] = $('#js-menu-bar-about ul li a[href="' + key + '"]');
          }
        }

        $(window).scroll(function () {
          for (var key in menus) {
            if ($(window).scrollTop() > menus[key] - 50) {
              $('#js-menu-bar-about ul li a').each(function() {
                $(this).removeClass('active');
              });
              globalNavi[key].addClass('active');
            }
          }
        });
      }

      if ($('#js-menu-bar-start').length) {
        const menus = {
          '#about-freelance': 0,
          '#step': 0,
          '#tax': 0,
          '#contract': 0,
          '#security': 0,
          '#registration': 0,
          '#career': 0,
        };

        let globalNavi = {};

        // 各要素のスクロール値を保存
        for (const key in menus) {
          if ($(key).offset()) {
            menus[key] = $(key).offset().top - 10;
            globalNavi[key] = $('#js-menu-bar-start ul li a[href="' + key + '"]');
          }
        }

        $(window).scroll(function () {
          for (var key in menus) {
            if ($(window).scrollTop() > menus[key] - 50) {
              $('#js-menu-bar-start ul li a').each(function() {
                $(this).removeClass('active');
              });
              globalNavi[key].addClass('active');
            }
          }
        });
      }

      if ($('#js-menu-bar-station').length) {
        const menus = {
          '#share': 0,
          '#station': 0,
          '#matching': 0,
          '#db': 0,
        };

        let globalNavi = {};

        // 各要素のスクロール値を保存
        for (const key in menus) {
          if ($(key).offset()) {
            menus[key] = $(key).offset().top - 10;
            globalNavi[key] = $('#js-menu-bar-station ul li a[href="' + key + '"]');
          }
        }

        $(window).scroll(function () {
          for (var key in menus) {
            if ($(window).scrollTop() > menus[key] - 50) {
              $('#js-menu-bar-station ul li a').each(function() {
                $(this).removeClass('active');
              });
              globalNavi[key].addClass('active');
            }
          }
        });
      }

    }
  }

  const initScrollLinks = () => {
    $('.js-link').click(function() {
      event.preventDefault();
      let anchor = $(this).attr('href');
      let offset = $(this).data('offset') || -160;
      window.history.pushState(null, null, $(this).attr('href'));
      $('html, body').animate({
        scrollTop: $(anchor).offset().top + offset
      }, 500);
    });
  }

  const initMemberModal = () => {
    if ($(".js-members").length) {
      $('.js-members li').click(function() {
        let target = $(this).data('target');
        let data = profileData[target];
        if (data) {
          $('#modal-profile-content').html(data.profile);
          $('#modal-profile-title').text(data.jp);
          $('#modal-profile-subtitle').text(data.en);
          MicroModal.show('modal-profile');
        }
      });
    }
  }

  const initFaq = () => {
    if ($(".js-qlist").length) {
      $('.js-qlist li').click(function() {
        $(".js-qlist li").removeClass("-active");
        $(".js-alist li").removeClass("-active");
        $(".js-alist li").hide();
        let target = $(this).data('target');
        $(target).fadeIn();
        $(this).addClass("-active");
      });
    }
  }

  const initNavDropdown = () => {
    $('.js-dropdown').click(function() {
      let target = $(this).data('target');
      let trigger = this;
      $(target).slideToggle(200, function() {
        $(trigger).toggleClass('-active');
      });
    });
  }

  const initNavMenu = () => {
    $(".js-nav-button").click(function() {
      $('.js-nav-button').toggleClass('--is-active');
      $(".js-nav-menu").toggleClass('--is-active');
      $(".js-nav-overlay").fadeToggle();

      if ($(".js-nav-menu").hasClass("--is-active") == true) {
        setTimeout(function() {
          const duration = 250;
          $('.js-nav-links li').each(function(i) {
            $(this).delay(i * (duration / 2)).fadeIn();
          });
          $(".js-nav-social").fadeIn();
        }, 500);
      } else {
        $('.js-nav-links li').hide();
        $(".js-nav-social").hide();
      }
    });
    $('.js-nav-links a').click(function() {
      $('.js-nav-links li').hide();
      $(".js-nav-social").hide();
      $('.js-nav-button').removeClass('--is-active');
      $(".js-nav-menu").removeClass('--is-active');
      $(".js-nav-overlay").fadeOut();
    });
  }


  const initSlider = () => {
    if ($(".js-voices").length) {
      var voiceSlider = tns({
        container: '.js-voices',
        gutter: 24,
        mouseDrag: true,
        fixedWidth: 260,
        loop: true,
        swipeAngle: false,
        controlsContainer: '.c-slider-horizontal__controls',
        prevButton: '.prev-button',
        nextButton: '.next-button',
        nav: false,
        speed: 400,
        responsive: {
          640: {
            edgePadding: 20,
            gutter: 20,
            fixedWidth: 320
            // items: 2
          },
        }
      });
    }
  }

  const initFreegalModal = () => {
    if (typeof needAgreeFreegal == 'undefined') {
      return;
    }
    if(needAgreeFreegal) {
      MicroModal.show('modal-freegal');
    }

    $('#agree-freegal').click(function() {
      if ($('#confirmFreegal:checked').length == 0) {
        alert("確認しましたのチェックをお願いします。")
        return;
      }

      $.ajax({
        type: 'POST',
        url: '/api/v1/user/agree_freegal',
        data: {}
      })
        .done(function(res) {
          MicroModal.close('modal-freegal');
        })
        .catch(function(res) {
          alert("情報の更新に失敗しました。")
        });
    });
  }

  const initRegisterProfileModal = () => {
    if (typeof needRegisterProfile == 'undefined') {
      return;
    }
    if(needRegisterProfile) {
      MicroModal.show('modal-register-profile');

      $.ajax({
        type: 'POST',
        url: '/api/v1/user/update_initial_dialog_displayed',
        data: {
          initial_dialog_displayed: 1
        }
      })
        .done(function(res) {
        })
        .catch(function(res) {
          alert("情報の更新に失敗しました。")
        });
    }

    $('#register-profile').click(function() {
      var places_heard_about_association_checks = [];
      $("[name='places_heard_about_association[]']:checked").each(function(){
        places_heard_about_association_checks.push(this.value);
      });

      $.ajax({
        type: 'POST',
        url: '/api/v1/user/update_initial_account',
        data: {
          registration_factor: $('#registration_factor').val(),
          places_heard_about_association: places_heard_about_association_checks,
          places_heard_about_association_other: $('#places_heard_about_association_other').val()
        }
      })
        .done(function(res) {
          MicroModal.close('modal-register-profile');
        })
        .catch(function(res) {
          alert("情報の更新に失敗しました。")
        });
    });
  }

  return {
    init: init
  }
})();

mainModule.init();

let profileModule = (function() {

  const init = () => {
    initActionButton();
    initProfileGallery();
    initFixedSidebar();
    initEventSlider();
    initRecommendModal();
    initMoreRecommend();
    initDetailModal();
  }

  const initDetailModal = () => {
    if ($(".js-recommends-list").length) {
      $('.js-recommends-list li').click(function() {
        var details = JSON.parse($(this).attr('data-details'));
        if (details) {
          $('#modal-detail-content').html(details.content);
          $('#modal-detail-subtitle').text(details.jobs);
          $('#modal-detail-link').text(details.name);
          $('#modal-detail-link').prop('href', details.link);
          $('#modal-detail-image').prop('style', "background-image: url('" +details.thumb+ "')");
        }
        MicroModal.show('modal-details');
      });
    }
  }

  const initRecommendModal = () => {
    if ($("#modal-recommend").length) {
      $('.js-comment-button').click(function() {
        $('.js-modal-error').hide();
        MicroModal.show('modal-recommend');
      });

      $('.js-recommend-submit').click(function() {
        const message = $('.js-recommend-message').val();
        const defer = sendRecommend(message);
        if (!defer) return;

        defer.
          done((res) => {
            addRecomend(res.recommends, true);
            $("ul[data-recommend='list']").attr("data-recommend-now-page", 1);
            $("ul[data-recommend='list']").attr("data-recommend-total-page", res.total_page);
            if (res.total_page > 1) {
              $('#more-recommend-button').show();
              initMoreRecommend();
            } else {
              $('#more-recommend-button').hide();
            }

            MicroModal.close('modal-recommend');
          }).
          fail((err) => {
            $('.js-modal-error').text(err.responseJSON.message);
            $('.js-modal-error').show();
          });
      });
    }
  }

  const initMoreRecommend = () => {
    const maxPage = Number($("ul[data-recommend='list']").attr("data-recommend-total-page"));
    $('#more-recommend-button').click(function() {
      const nextPage = Number($("ul[data-recommend='list']").attr("data-recommend-now-page")) + 1;
      if (maxPage < nextPage) return

      const defer = getMoreRecommend(nextPage);
      if (!defer) return;

      defer.
        done((res) => {
          addRecomend(res.recommends);

          $("ul[data-recommend='list']").attr("data-recommend-now-page", nextPage);

          if (maxPage == nextPage) {
            $('#more-recommend-button').hide();
          }
        }).
        fail((err) => {
          $('.js-modal-error').text(err.responseJSON.message);
          $('.js-modal-error').show();
        });
    });
  }

  const addRecomend = (recommends, refresh = false) => {
    if (refresh) {
      $("ul[data-recommend='list']").empty();
    }

    recommends.forEach((recommend) => {
      const recommendEscapeJson = escapeHtml(JSON.stringify(recommend));
      const newRecommend = '<li class="c-list-recommends__item" data-recommend-from="' + recommend.member_id + '" data-details="' + recommendEscapeJson + '">'
       + '<div><div class="item-image" style="background-image: url(\'' + recommend.thumb + '\')"></div></div>'
       + '<div class="item-content">'
       + '<p class="item-name">' + recommend.name + '</p>'
       + '<p class="item-message" data-recommend-message-from="' + recommend.member_id + '">' + recommend.content.replace(/<br \/>|<br>/g, ' ').substring(0, 100) + '</p>'
       + '</div>'
       + '</li>';

      $("ul[data-recommend='list']").append(newRecommend);
    });

    initDetailModal();
  }

  const initEventSlider = () => {
    if ($(".js-event-list").length) {
      var voiceSlider = tns({
        container: '.js-event-list',
        gutter: 12,
        mouseDrag: true,
        loop: true,
        swipeAngle: false,
        items: 1.2,
        controls: false,
        controlsContainer: '.c-list-events__controls',
        prevButton: '.prev-button',
        nav: false,
        speed: 400,
        responsive: {
          769: {
            controls: true,
            gutter: 24,
            nextButton: '.next-button',
            items: 2
          },
        }
      });
    }
  }

  const initFixedSidebar = () => {
    if ($(window).width() > 640) {
      if ($('.js-p-sidebar').length) {
        var sidebar = new StickySidebar('.js-p-sidebar', {
          topSpacing: 120,
          bottomSpacing: 0
        });
      }
    }
  }

  const initProfileGallery = () => {
    if ($(".js-gallery").length) {
      var imageGallery = tns({
        container: '.js-gallery',
        mouseDrag: true,
        mode: 'gallery',
        nav: true,
        navContainer: '.js-gallery-nav',
        controls: true,
        speed: 400,
        controlsText: ["", ""],
        responsive: {
          1025: {
            navAsThumbnails: true,
            controls: false,
            mouseDrag: false
          },
        }
      });
    }
  }

  const resetFavoriteCounts = () => {
    const countupables = $('[data-toggle="favorite-countup"]');
    if (!countupables.length) return;

    countupables.each((_index, dom) => {
      const countText = $(dom).find('span');
      countText.text(countText.data('count'));
    });
  }

  const countupFavorite = (action) => {
    const target = $('[data-toggle="favorite-countup"][data-action="' + action + '"]');
    if (!target.length) return;

    resetFavoriteCounts();
    const countText = target.find('span');
    const count = countText.data('count') | 0;
    countText.text(count + 1);
  }

  const initActionButton = () => {
    const actionLike = $('#action1');
    if (!actionLike.length) return;

    const actionFriend = $('#action2');
    const actionWork = $('#action3');
    const actionSuper = $('#action4');
    const actionDefault = $('#action0');
    var nowAction = $('.js-actions').data('actions-status');

    $('.js-action').on('change', function() {
      const talentId = $(this).find("option:selected").data("talent-id");
      const action = $(this).find("option:selected").data("action");
      // 同一のactionが選ばれた場合は評価を取り消す
      if(action == nowAction) {
        resetFavoriteCounts();
        sendDeleteAction(talentId);
        $('.js-actions').removeClass('-active');
        nowAction = 'default';
      } else {
        countupFavorite(action);
        sendAction(talentId, action);
        nowAction = action;
      }
    });
  }

  const sendAction = (talentId, action) => {
    $.post('/api/v1/talent_favorites/add', {
      talent: talentId,
      favorite_action: action
    }).
    fail(function(err) {
      alert(err.responseJSON.message);
    });
  }

  const sendDeleteAction = (talentId) => {
    $.post('/api/v1/talent_favorites/delete', {
      talent: talentId
    }).
    fail(function(err) {
      alert(err.responseJSON.message);
    });
  }

  const sendRecommend = (message) => {
    if (!ajaxConfig) return null;

    return $.post('/api/v1/talent_recommends/submit', {
      talent: ajaxConfig.talentId,
      recommend: message
    });
  }

  const getMoreRecommend = (page) => {
    if (!ajaxConfig) return null;

    return $.get('/api/v1/talent_recommends/' + ajaxConfig.talentId, {page: page});
  }

  const getClassForAction = (action) => {
    switch (action) {
      case "like":
        return "--like"
        break;
      case "friend":
        return "--friend"
        break;
      case "work":
        return "--work"
        break;
      case "super":
        return "--super"
        break;
      default:

    }
  }

  const getTextForAction = (action) => {
    switch (action) {
      case "like":
        return "気になる"
        break;
      case "friend":
        return "知ってる"
        break;
      case "work":
        return "仕事した"
        break;
      case "super":
        return "イチオシ"
        break;
      default:
        return "選択する"
    }
  }

  const escapeHtml = (string) => {
    if(typeof string !== 'string') {
      return string;
    }
    return string.replace(/[&'`"<>]/g, function(match) {
      return {
        '&': '&amp;',
        "'": '&#x27;',
        '`': '&#x60;',
        '"': '&quot;',
        '<': '&lt;',
        '>': '&gt;',
      }[match]
    });
  }

  return {
    init: init
  }
})();

profileModule.init();

let talentModule = (function() {

  const init = () => {
    initFilterChoices();
    initAddAction();
    initFixedSidebar();
  }

  const initFixedSidebar = () => {
    if ($(window).width() > 640) {
      if ($('#sidebar').length) {
        var sidebar = new StickySidebar('#sidebar', {
          topSpacing: 144,
          bottomSpacing: 0
        });
      }
    }

    if ($(".mypage-hamburger").length) {
      $(".mypage-hamburger").on('click', function() {
        $(this).toggleClass('active');
        $("aside.c-sidebar").toggleClass('active');
      });
    }
  }

  const initFilterChoices = () => {
    let areaInput = $('.js-area-input')[0];
    let jobInput = $('.js-job-input')[0];
    let statusInput = $('.js-status-input')[0];
    let approvedInput = $('.js-approved-input')[0];
    let displayNoFlyerInput = $('.js-display-no-flyer-input')[0];
    if (areaInput) {
      const areaChoices = new Choices(areaInput, {
        itemSelectText: '',
        shouldSort: false
      });
      const jobChoices = new Choices(jobInput, {
        itemSelectText: '',
        shouldSort: false
      });
      const statusChoices = new Choices(statusInput, {
        itemSelectText: '',
        shouldSort: false
      });
      const approvedChoices = new Choices(approvedInput, {
        itemSelectText: '',
        shouldSort: false
      });
      const displayNoFlyerChoices = new Choices(displayNoFlyerInput, {
        itemSelectText: '',
        shouldSort: false
      });
    }
  }

  const initAddAction = () => {
    $(".js-action-click").click(function() {
      document.addEventListener('click', actionListener);
    });

    const actionListener = (event) => {
      if (!$(event.target).closest('.js-action-click').length) {
        document.removeEventListener('click', actionListener);
      }
    };

    $(".js-action-item li").click(function() {
      const profile_id = $(this).data('profile');
      const action = $(this).data('action');
      const imgPath = $(this).find('img').attr('src');
      const profileIconId = '#js-action-status-' + profile_id;
      var nowAction = $(profileIconId).attr("data-js-action");

      const originalProfileImage = $(profileIconId).attr('src');
      const originalActive = $(profileIconId).hasClass('-active');

      const setFavorite = (action, nowAction, isActive) => {
        const activeClass = isActive ? '-active' : '';
        $(profileIconId).attr('data-js-action', action);
        $(profileIconId).addClass(activeClass);
        $(profileIconId + "~ul>li[data-action='" + action + "']").addClass('-selected');
        $(profileIconId + "~ul>li[data-action='" + nowAction + "']").removeClass('-selected');
      }

      const removeFavorite = (nowAction) => {
        $(profileIconId).attr('data-js-action', 'add');
        $(profileIconId).removeClass('-active');
        $(profileIconId + "~ul>li[data-action='" + nowAction + "']").removeClass('-selected');
      }

      const resetFavorite = () => {
        setFavorite(originalProfileImage, originalActive);
      }

      // 同一のactionが選ばれた場合は評価を取り消す
      if (action == nowAction) {
        removeFavorite(nowAction);
        $.post('/api/v1/talent_favorites/delete', {
          talent: profile_id
        }).
        fail(function(err) {
          alert(err.responseJSON.message);
        });
      } else {
        setFavorite(action, nowAction, true);
        $.post('/api/v1/talent_favorites/add', {
          talent: profile_id,
          favorite_action: action
        }).
        fail(function(err) {
          resetFavorite();
          alert(err.responseJSON.message);
        });
      }
    });
  }

  return {
    init: init
  }
})();

talentModule.init()

let mypageModule = (function() {
  const init = () => {
    initAvailabilitySelection();
  }

  const initAvailabilitySelection = () => {
    $('.js-availability').change(function(evt) {
      const status = $(evt.target).find('option:selected').val();
      sendAvailability(status);
    });
  }

  const sendAvailability = (status) => {
    if (!ajaxConfig) return null

    return $.post('/api/v1/talent_availability/update', {
      user_id: ajaxConfig.user_id,
      status: status
    });
  }

  return {
    init: init
  }
})();

mypageModule.init()
